/* Desktops and laptops ----------- */
@media only screen and (min-width: 1224px) {
	.demographics-main {
		background-color: #565454;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.stat-card.card {
		width: 40%;
		background-color: #fafbfb;
		margin-bottom: 0.5rem;
	}
}
