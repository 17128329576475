/* phones and ipads ----------- */
@media only screen and (max-width: 763px) {
	.bangedup-main {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		margin-top: 60px;
	}

	.sort {
		width: 250px;
		margin: 0 auto;
		margin-bottom: 5px;
	}

	.h5 {
		font-size: 1rem;
	}
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 1224px) {
	.bangedup-main {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-around;
		margin-top: 75px;
	}

	.sort {
		margin-left: 2%;
		margin-bottom: 5px;
	}
}
