/* Smartphones (portrait) ----------- */
@media only screen and (max-width: 375px) {
	.bangedup-container {
		display: flex;
		background-color: #565454;
	}

	.w-card.card {
		width: 100vw;
	}

	.w-1-col {
		width: 15%;
	}

	.w-2-col {
		width: 85%;
	}

	#spinner {
		position: absolute;
		margin-left: 13%;
		margin-top: 3%;
	}
}

/* iPads (portrait) ----------- */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
	.w-card.card {
		padding-left: 8rem;
		padding-right: 8rem;
	}

	.card-title.h5 {
		width: 20rem;
		font-size: 1.5rem;
	}

	#spinner {
		position: absolute;
		margin-left: 6%;
		margin-top: 2%;
	}
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 1224px) {
	.card-title.h5 {
		width: 22rem;
	}
	.w-card.card {
		width: 34rem;
	}

	.w-card.card:hover {
		cursor: pointer;
	}

	#spinner {
		position: absolute;
		margin-left: 9%;
		margin-top: 3%;
	}
}
