/* phones and ipads ----------- */
@media only screen and (max-width: 763px) {
	.users-name {
		display: none;
		visibility: hidden;
	}
}

/* Desktops and laptops ----------- */
@media only screen and (min-width: 1224px) {
}
